import { useDialog } from '@component-utils/dialogs'
import { useErrorToast } from '@component-utils/toasts'
import { Controller } from '@hotwired/stimulus'
import SummaryDocumentsApi from '~/api/ContractSummaries/SummaryDocumentsApi'
import SummaryPreview_ from '~/views/templates/components/Summary/SummaryPreview.vue'
import openDialog from '~/views/templates/components/Summary/utils'

type HeaderType = 'Clause' | 'Standard' | 'Updated' | 'Approval status' | 'Approver'

export default class SummaryPreview extends Controller {
  static values = {
    id: String
  }

  declare readonly idValue: string
  
  async openDialog(){
    openDialog(this.idValue)
  }
}